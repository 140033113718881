/** @jsx jsx */
import { jsx } from 'theme-ui'
import { MDXProvider } from '@mdx-js/react'
import MDXRenderer from 'gatsby-plugin-mdx/mdx-renderer'
import { Link } from 'gatsby'
//

const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#333"
      d="M18.226 8.886c-1.59.579-2.595 2.071-2.481 3.704l.038.63-.636-.077c-2.315-.296-4.338-1.299-6.056-2.984l-.84-.836-.215.617c-.458 1.376-.165 2.83.789 3.808.509.54.394.617-.483.296-.305-.103-.573-.18-.598-.141-.089.09.216 1.26.458 1.724.331.644 1.005 1.273 1.743 1.647l.624.296-.739.011c-.712 0-.738.013-.661.284.254.836 1.259 1.724 2.379 2.11l.789.27-.687.412a7.122 7.122 0 01-3.41.951c-.573.013-1.044.064-1.044.103 0 .128 1.553.848 2.455 1.132 2.71.836 5.929.475 8.346-.952 1.718-1.016 3.435-3.036 4.237-4.992.433-1.041.865-2.945.865-3.858 0-.592.038-.669.75-1.376.42-.412.814-.862.891-.99.128-.245.114-.245-.534-.026-1.081.386-1.234.335-.699-.244.394-.412.865-1.158.865-1.376 0-.038-.191.026-.407.141-.229.129-.738.322-1.12.437l-.687.219-.623-.426c-.344-.231-.826-.489-1.081-.566-.65-.18-1.642-.154-2.228.052z"
    ></path>
  </svg>
)

const BehanceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <g fill="#333">
      <path d="M14.289 15.596s1.678-.128 1.678-2.136c0-2.007-1.138-2.996-2.878-2.996l-5.961-.009v11.09h6.099s3.05.096 3.05-3.327c0 0 .224-2.622-1.988-2.622zm-4.943-3.478h3.05s1.044.017 1.044 1.37c0 1.402-1.044 1.402-1.044 1.402h-3.05v-2.772zm3.327 7.763H9.346v-3.327h3.327s1.663.017 1.663 1.663c.001 1.647-1.423 1.664-1.663 1.664zM20.99 13.227c-4.166 0-4.159 4.159-4.159 4.159s-.277 4.159 4.159 4.159c0 0 3.881 0 3.881-3.327h-2.218s0 1.663-1.663 1.663c0 0-1.663 0-1.663-2.218h5.545c0-1.109 0-4.436-3.882-4.436zm-1.663 3.327s-.037-1.663 1.663-1.663 1.663 1.663 1.663 1.663h-3.326z"></path>
      <path d="M18.772 11.564H23.208V12.673H18.772z"></path>
    </g>
  </svg>
)

const InstagramIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#333"
      d="M22.057 7.924H9.943c-1.14 0-2.019.879-2.019 2.019v12.113c0 1.14.879 2.019 2.019 2.019h12.113c1.14 0 2.019-.879 2.019-2.019V9.943a1.987 1.987 0 00-2.018-2.019zm-6.045 4.903c1.791 0 3.243 1.407 3.243 3.142 0 1.735-1.452 3.142-3.243 3.142-1.79 0-3.242-1.408-3.242-3.142 0-1.735 1.452-3.142 3.242-3.142zm6.045 8.725c0 .354-.151.505-.505.505H10.448c-.353 0-.505-.151-.505-.505v-7.066l1.258.274a4.7 4.7 0 00-.208 1.385c0 2.684 2.248 4.863 5.018 4.863 2.772 0 5.019-2.178 5.019-4.863a4.7 4.7 0 00-.208-1.385l1.234-.274v7.066zm0-9.085a.505.505 0 01-.505.505h-2.019a.505.505 0 01-.505-.505v-2.019c0-.279.226-.505.505-.505h2.019c.279 0 .505.226.505.505v2.019z"
    ></path>
  </svg>
)

const DribbleIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#333"
      d="M22.644 10.534c-.016.023-1.396 2.017-4.793 3.404a27.017 27.017 0 01.758 1.694c2.956-.372 5.868.253 5.992.279a8.566 8.566 0 00-1.957-5.377zm-3.511 6.456c1.128 3.101 1.587 5.627 1.675 6.146a8.607 8.607 0 003.687-5.771c-.178-.058-2.585-.82-5.362-.375zm.226 6.932c-.127-.752-.627-3.387-1.837-6.533l-.058.019c-5.076 1.769-6.734 5.335-6.752 5.375a8.587 8.587 0 008.647 1.139zm-2.17-11.266c3.246-1.217 4.472-3.082 4.493-3.114A8.566 8.566 0 0016 7.397c-.699 0-1.378.085-2.027.241.128.173 1.744 2.317 3.216 5.018zm-.255 3.307a29.504 29.504 0 00-.698-1.462c-4.43 1.326-8.681 1.231-8.833 1.225-.002.092-.007.181-.007.274 0 2.21.834 4.226 2.204 5.748-.003-.004 2.352-4.181 6.995-5.683.112-.036.226-.07.339-.102zm8.935-1.991a10.114 10.114 0 010 4.056c-.133.649-.33 1.285-.587 1.894a10.137 10.137 0 01-3.649 4.432c-.539.363-1.115.674-1.712.928a10.094 10.094 0 01-3.922.793 10.145 10.145 0 01-3.922-.793 10.294 10.294 0 01-1.712-.928 10.143 10.143 0 01-3.648-4.432 10.09 10.09 0 01-.589-1.894 10.272 10.272 0 01.001-4.057 10.04 10.04 0 012.747-5.096 10.133 10.133 0 013.201-2.159 9.93 9.93 0 011.893-.587 10.137 10.137 0 015.952.588c.597.253 1.173.566 1.712.931.533.361 1.035.774 1.491 1.228a10.137 10.137 0 012.158 3.203c.257.607.454 1.244.586 1.893zm-10.345-.796a51.643 51.643 0 00-3.195-4.958 8.615 8.615 0 00-4.749 6.009c.227.001 3.813.046 7.944-1.051z"
    ></path>
  </svg>
)

const Icons = {
  'https://www.instagram.com/gelatina__d/': TwitterIcon,
  'https://twitter.com/cr_i_s_t_i_na': InstagramIcon,
  'https://www.behance.net/cristinasusano': BehanceIcon,
  'https://dribbble.com/CristinaSusano': DribbleIcon
}

const MdxField = ({ children, handleToggleMenu = () => {} }) => (
  <MDXProvider
    components={{
      a: ({ href, children: child }) => {
        const to = href.replace(process.env.SITE_URL, '')

        if (to.startsWith('/')) {
          return (
            <Link sx={{ color: 'text' }} onClick={() => handleToggleMenu(false)} to={to}>
              {child}
            </Link>
          )
        }

        if (Icons[to]) {
          const SocialIcon = Icons[to]

          return (
            <a
              sx={{ color: 'text', display: 'flex', alignItems: 'center', textDecoration: 'none' }}
              data-link-external
              href={href}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              <span sx={{ width: '32px', height: '32px' }}>
                <SocialIcon />
              </span>
              {child}
            </a>
          )
        }

        if (to)
          return (
            <a
              sx={{
                color: 'text',
                textDecoration: 'none',
                borderBottomWidth: '1px',
                borderBottomColor: 'text',
                borderBottomStyle: 'solid',
                ':hover': {
                  borderBottomWidth: '0px'
                }
              }}
              data-link-external
              href={href}
              target="_blank"
              rel="noopener noreferrer nofollow"
            >
              {child}
            </a>
          )
      }
    }}
  >
    <MDXRenderer>{children}</MDXRenderer>
  </MDXProvider>
)

export default MdxField
