/** @jsx jsx */
import { Styled, jsx, Grid, Box } from 'theme-ui'
import { graphql } from 'gatsby'
//
import Layout from '../components/layout'
import Seo from '../components/seo'
import Footer from '../components/footer'
import { ImageFluid } from '../utils/image'
import MdxField from '../utils/mdx'

const About = ({ data, location }) => {
  const { title, description, body } = data?.contentfulPage
  const { image } = data

  return (
    <Layout location={location}>
      <Seo title={title} description={description?.description} cardType="summary" />

      <Styled.div sx={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
        <Styled.div sx={{ flex: '1 0 auto' }}>
          <Styled.h2 sx={{ mb: 1, fontWeight: 400 }}>{title}</Styled.h2>
          <Styled.div sx={{ width: '100%', maxWidth: '1280px', mb: 4, pb: 4 }}>
            <Grid sx={{ display: ['none', 'grid'] }} gap={4} columns={[1, '4fr 2fr']}>
              <Box>
                <MdxField>{body?.childMdx?.body}</MdxField>
              </Box>
              <Box sx={{ p: 2 }}>
                {image ? <ImageFluid sx={{ borderRadius: 4 }} image={image?.childImageSharp} /> : null}
              </Box>
            </Grid>
            <Grid sx={{ display: ['grid', 'none'] }} gap={2} columns={1}>
              <Box sx={{ mt: 2 }}>
                {image ? <ImageFluid sx={{ borderRadius: 4 }} image={image?.childImageSharp} /> : null}
              </Box>
              <Box>
                <MdxField>{body?.childMdx?.body}</MdxField>
              </Box>
            </Grid>
          </Styled.div>
        </Styled.div>
        <Footer />
      </Styled.div>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query AboutPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    image: file(relativePath: { eq: "cristina.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 540, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    contentfulPage(slug: { eq: "about" }) {
      id
      title
      description {
        description
      }
      body {
        childMdx {
          body
        }
      }
    }
  }
`
